import React, { Component } from "react";
import {
  Layout,
  Table,
  Row,
  Col,
  DatePicker,
  Button,
  message,
  Icon,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  ClassSectionDropdown,
  AcademicYearDropdown,
} from "../../../components";
import { api } from "../../../config";
import { connect } from "react-redux";

class AttendanceList extends Component {
  state = {
    class_id: null,
    date: moment(),
    students_list: [],
    ac_year_id: null,
  };

  componentDidMount() {}

  classChange = (val) => {
    this.setState({ class_id: val });
  };

  dateChange = (val) => {
    this.setState({ date: val.format("YYYY-MM-DD") });
  };

  handleGo = () => {
    let { class_id, date, ac_year_id } = this.state;
    if (!(class_id && date && ac_year_id)) {
      message.error("Please Select class, section, academic year and date");
      return;
    }
    let url =
      "/attendance/students/" + class_id + "/" + date + "/" + ac_year_id + "/";
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ students_list: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      {
        title: "Admn. No.",
        dataIndex: "admission_no",
      },
      {
        title: "Name",
        render: (record) => (
          <span>
            <Link to={"/students/details/" + record.student_id}>
              {record.fullname}
            </Link>
          </span>
        ),
      },
      {
        title: "Status",
        render: (record) => (
          <span>
            {record.present !== null
              ? record.present === 1
                ? "Present"
                : "Absent"
              : "Not Taken"}
          </span>
        ),
      },
      {
        title: "This Year",
        render: (record) => (
          <span>
            {record.present_days}/{record.total_days}
          </span>
        ),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              onClick={() => {
                console.log(record);
                this.props.history.push({
                  pathname: "/students/edit/" + record.student_id,
                  state: { record },
                });
              }}
            />
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <h1>Attendance List</h1>
        <Row gutter={32} style={{ marginBottom: 20 }}>
          <Col sm={6}>
            <span>Class : </span>
            <ClassSectionDropdown
              onChange={this.classChange}
              value={this.state.class_id}
            />
          </Col>
          <Col sm={4}>
            <span>AC Year : </span>
            <AcademicYearDropdown
              value={this.state.ac_year_id}
              onChange={(ac_year_id) => {
                this.setState({ ac_year_id });
              }}
            />
          </Col>
          <Col sm={4}>
            <span>Date : </span>
            <DatePicker
              defaultValue={moment()}
              onChange={this.dateChange}
              style={{ width: 160, maxWidth: "100%" }}
            />
          </Col>
          <Col sm={2}>
            <Button onClick={this.handleGo}>Go</Button>
          </Col>
          <Col sm={8} style={{ textAlign: "right" }}>
            <Link to="/attendance/update">
              <Button type="primary">Update Attendance</Button>
            </Link>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.students_list}
          rowKey="student_id"
        />
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(AttendanceList);
