import React, { Component } from "react";
import { Layout, Menu, Icon } from "antd";
import { UserHeaderMenu, PageFooter } from "./HeaderFooter";
import { AdminRouter } from "../../config/Router";
import { Link } from "react-router-dom";
import { api } from "../../config";

const width = window.innerWidth;
const { Sider } = Layout;

class AdminLayout extends Component {
  state = {
    collapsed: false,
    selectedKeys: [],
    modules: [],
  };

  componentDidMount() {
    this.get_modules_list();
  }

  get_modules_list = () => {
    api
      .get("/admin/modules/")
      .then((res) => {
        if (res.data.success) {
          // store this in redux
          this.setState({ modules: res.data.data });
        }
      })
      .catch((err) => {});
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  onSelect = ({ item, key, keyPath, selectedKeys, e }) => {
    if (width < 480) {
      this.setState({ collapsed: true, selectedKeys });
    } else {
      this.setState({ selectedKeys });
    }
  };

  render() {
    const { about_school, role } = this.props;
    const { modules } = this.state;
    return (
      <Layout>
        <UserHeaderMenu />
        <Layout style={{ minHeight: "100vh" }}>
          {modules.length > 0 ? (
            <AdminMenu
              collapsed={this.state.collapsed}
              onCollapse={this.onCollapse}
              onSelect={this.onSelect}
              selectedKeys={this.state.selectedKeys}
              modules={modules}
            />
          ) : (
            <AdminMenu
              collapsed={this.state.collapsed}
              onCollapse={this.onCollapse}
              onSelect={this.onSelect}
              selectedKeys={this.state.selectedKeys}
              modules={about_school.modules || []}
            />
          )}
          <Layout className="box-container">
            <AdminRouter role={role} />
          </Layout>
        </Layout>
        <PageFooter />
      </Layout>
    );
  }
}

const AdminMenu = (props) => (
  <Sider
    width={210}
    breakpoint="md"
    collapsible
    collapsed={props.collapsed}
    onCollapse={props.onCollapse}
    collapsedWidth={width < 480 ? 0 : 80}
    theme="light"
    style={{
      boxShadow: "2px 0px 2px #e8e8e8",
      borderRight: "1px solid #e8e8e8",
    }}
  >
    <div className="sider-fixed-container">
      <Menu
        mode="inline"
        theme="light"
        onSelect={props.onSelect}
        selectedKeys={props.selectedKeys}
        key="side-menu"
      >
        {props.modules &&
          props.modules.map((item, index) => {
            if (item.hidden) {
              return (
                <span style={{ display: "none" }} key={item.module_key}></span>
              );
            }
            if ("read" in item && !item.read) {
              return (
                <span style={{ display: "none" }} key={item.module_key}></span>
              );
            }
            if (!item.children) {
              return (
                <Menu.Item key={item.module_key} disabled={!item.enabled}>
                  <Link to={item.link}>
                    <Icon type={item.icon} />{" "}
                    <span>
                      {item.module_name || item.title}{" "}
                      {!item.enabled && (
                        <sup className="tag-pro-version">Pro</sup>
                      )}
                    </span>
                  </Link>
                </Menu.Item>
              );
            } else {
              return (
                <Menu.SubMenu
                  key={item.module_key}
                  title={
                    <span>
                      <Icon type={item.icon} />{" "}
                      <span className="menu-item-title">
                        {item.module_name || item.title}{" "}
                        {!item.enabled && (
                          <sup className="tag-pro-version">Pro</sup>
                        )}
                      </span>
                    </span>
                  }
                  disabled={!item.enabled}
                >
                  {item.children.map((it, ind) => {
                    return (
                      <Menu.Item key={it.module_key} disabled={!it.enabled}>
                        <Link to={it.link}>
                          <span>
                            {it.module_name || it.title}{" "}
                            {!it.enabled && (
                              <sup className="tag-pro-version">Pro</sup>
                            )}
                          </span>
                        </Link>
                      </Menu.Item>
                    );
                  })}
                </Menu.SubMenu>
              );
            }
          })}
      </Menu>
    </div>
  </Sider>
);

export default AdminLayout;
