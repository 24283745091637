import React, { Component } from "react";
import { Layout, message, Button, Row, Col } from "antd";
import { connect } from "react-redux";
import { api, baseurl } from "../../../../config";

var moment = require("moment");

class FeeRecipt extends Component {
  state = {
    fee: {},
    receipt_id: null,
    about_school: {},
  };

  componentDidMount() {
    this.getAboutSchool();
    if (this.props.match.params.id) {
      this.getFeeParticulars(this.props.match.params.id);
      this.setState({ receipt_id: this.props.match.params.id });
    }
  }

  getAboutSchool = () => {
    this.setState({ about_school: this.props.about_school });
  };

  getFeeParticulars = (id) => {
    api
      .get("/fees/receipt/" + id + "/")
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ fee: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { details, payment, student, ac_year, installments } = this.state.fee;
    const student_class = this.state.fee.class;
    const { about_school } = this.state;
    return (
      <Layout.Content>
        <Row>
          <Col sm={20}>
            <h1>Fee Receipt</h1>
          </Col>
          <Col sm={4}>
            <Button
              type="primary"
              onClick={() => {
                window.print();
                this.props.history.push("/fees/records/" + student.id + "/");
              }}
            >
              Print Receipt
            </Button>
          </Col>
        </Row>
        <div className="fee-receipt">
          <div className="receipt-copy institution-copy">
            <div className="receipt-header">
              <div className="logo">
                <img
                  src={baseurl + "/" + about_school.school_logo}
                  alt="Logo"
                />
              </div>
              <div className="school-address">
                <h2>{about_school.school_name}</h2>
                <p>{about_school.school_address}</p>
              </div>
            </div>
            <hr />
            <div className="receipt-num-date">
              <div className="receipt-number">
                <b>No. : </b>
                {this.state.receipt_id}
              </div>
              <div className="receipt-date">
                <b>Date : </b>
                {new Date(payment && payment.timestamp).toDateString()}
              </div>
            </div>
            <hr />
            <div className="student-details">
              <div className="student-name">
                <b>Student Name : </b>
                {student && student.fullname}
              </div>
              <div className="student-class">
                <b>Class : </b>
                {student_class &&
                  student_class.class + " " + student_class.section}
              </div>
            </div>
            <div className="student-details">
              <div className="admission-no">
                <b>Admission No.: </b>
                {student && student.admission_no}
              </div>
              <div className="fee-term">
                <p>
                  <b>AY : </b>
                  {ac_year && ac_year.name}
                </p>
              </div>
            </div>
            <hr />
            <table className="receipt-table">
              <thead>
                <tr>
                  <th className="table-name">Name</th>
                  <th className="table-quantity">Qty.</th>
                  <th className="table-amount">Amount</th>
                </tr>
              </thead>
              <tbody>
                {details &&
                  details.map((item, index) => {
                    if (item.amount !== 0)
                      return (
                        <tr key={index}>
                          <td>{item.fee_item_name}</td>
                          <td>{item.quantity}</td>
                          <td className="table-money">₹ {item.amount}</td>
                        </tr>
                      );
                    else return null;
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={2}>Total</th>
                  <th className="table-money">
                    ₹ {payment && payment.total_amount}
                  </th>
                </tr>
                <tr className="receipt-paid">
                  <th colSpan={2}>Paid</th>
                  <th className="table-money">
                    ₹ {payment && payment.paid_amount}
                  </th>
                </tr>
                {installments &&
                  installments.map((item, index) => {
                    return (
                      <tr key={index} className="receipt-installment">
                        <th colSpan={2}>
                          Installment {index + 1} (paid on &nbsp;
                          {moment(item.date || item.timestamp).format(
                            "DD-MM-YYYY"
                          )}
                          )
                        </th>
                        <th className="table-money">₹ {item.paid_amount}</th>
                      </tr>
                    );
                  })}
                <tr>
                  <th colSpan={2}>Balance</th>
                  <th className="table-money">
                    ₹ {payment && payment.total_amount - payment.paid_amount}
                  </th>
                </tr>
              </tfoot>
            </table>
            <p className="institution-copy">Institution Copy</p>
            <div className="signature-block">
              <p>Institution Seal</p>
            </div>
          </div>
          <div className="receipt-copy student-copy">
            <div className="receipt-header">
              <div className="logo">
                <img
                  src={baseurl + "/" + about_school.school_logo}
                  alt="Logo"
                />
              </div>
              <div className="school-address">
                <h2>{about_school.school_name}</h2>
                <p>{about_school.school_address}</p>
              </div>
            </div>
            <hr />
            <div className="receipt-num-date">
              <div className="receipt-number">
                <b>No. : </b>
                {this.state.receipt_id}
              </div>
              <div className="receipt-date">
                <b>Date : </b>
                {new Date(payment && payment.timestamp).toDateString()}
              </div>
            </div>
            <hr />
            <div className="student-details">
              <div className="student-name">
                <b>Student Name : </b>
                {student && student.fullname}
              </div>
              <div className="student-class">
                <b>Class : </b>
                {student_class &&
                  student_class.class + " " + student_class.section}
              </div>
            </div>
            <div className="student-details">
              <div className="admission-no">
                <b>Admission No.: </b>
                {student && student.admission_no}
              </div>
              <div className="fee-term">
                <p>
                  <b>AY : </b>
                  {ac_year && ac_year.name}
                </p>
              </div>
            </div>
            <hr />
            <table className="receipt-table">
              <thead>
                <tr>
                  <th className="table-name">Name</th>
                  <th className="table-quantity">Qty.</th>
                  <th className="table-amount">Amount</th>
                </tr>
              </thead>
              <tbody>
                {details &&
                  details.map((item, index) => {
                    if (item.amount !== 0)
                      return (
                        <tr key={index}>
                          <td>{item.fee_item_name}</td>
                          <td>{item.quantity}</td>
                          <td className="table-money">₹ {item.amount}</td>
                        </tr>
                      );
                    else return null;
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan={2}>Total</th>
                  <th className="table-money">
                    ₹ {payment && payment.total_amount}
                  </th>
                </tr>
                <tr className="receipt-paid">
                  <th colSpan={2}>Paid</th>
                  <th className="table-money">
                    ₹ {payment && payment.paid_amount}
                  </th>
                </tr>
                {installments &&
                  installments.map((item, index) => {
                    return (
                      <tr key={index} className="receipt-installment">
                        <th colSpan={2}>
                          Installment {index + 1} (paid on &nbsp;
                          {moment(item.date || item.timestamp).format(
                            "DD-MM-YYYY"
                          )}
                          )
                        </th>
                        <th className="table-money">₹ {item.paid_amount}</th>
                      </tr>
                    );
                  })}
                <tr>
                  <th colSpan={2}>Balance</th>
                  <th className="table-money">
                    ₹ {payment && payment.total_amount - payment.paid_amount}
                  </th>
                </tr>
              </tfoot>
            </table>
            <p className="institution-copy">Student Copy</p>
            <div className="signature-block">
              <p>Institution Seal</p>
            </div>
          </div>
        </div>
      </Layout.Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    about_school: state.about_school,
  };
}

export default connect(mapStateToProps, null)(FeeRecipt);
