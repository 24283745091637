import React, { Component } from "react";
import { Layout, Form, Input, Upload, Button, message } from "antd";
import {
  ClassSectionDropdown,
  SubjectSelectionDropdown
} from "../../../components";
import { baseurl, getToken, api } from "../../../config";

class AddNote extends Component {
  state = {
    _class: null
  };

  componentDidMount() {
    // console.log(this.props.history);
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        let data = val;
        data.url = val.url[0].response.path;
        api
          .put("/notes/", JSON.stringify(data))
          .then(res => {
            if (res.data && res.data.success) {
              message.success("Note Added");
            //   this.props.history.goBack();
            } else {
              message.error(res.data.error);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    return (
      <Layout.Content>
        <h1>Add new notes</h1>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Class">
            {getFieldDecorator("class_id", {
              rules: [{ required: true, message: "Class is required" }]
            })(
              <ClassSectionDropdown
                onChange={_class => {
                  this.setState({ _class });
                }}
              />
            )}
          </FormItem>
          <FormItem label="Subject">
            {getFieldDecorator("subject_id")(
              <SubjectSelectionDropdown class={this.state._class} />
            )}
          </FormItem>
          <FormItem label="Name">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Name is required" }]
            })(<Input placeholder="Name" />)}
          </FormItem>
          <FormItem label="Description">
            {getFieldDecorator("description")(
              <Input.TextArea placeholder="Description" />
            )}
          </FormItem>
          <FormItem label="File Upload">
            {getFieldDecorator("url", {
              rules: [{ required: true, message: "File is required" }],
              valuePropName: "fileList",
              getValueFromEvent: this.normFile
            })(
              <Upload
                name="file"
                action={baseurl + "/upload/"}
                headers={{ Authorization: getToken() }}
                listType="picture"
                accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.ppt,.pptx,.odt,.odp,.txt,.rtf"
              >
                <Button icon="upload">Upload</Button>
              </Upload>
            )}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button type="primary" onClick={this.handleSubmit}>
              Add Note
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(AddNote);
