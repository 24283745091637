import { Button, Form, Input, Layout, message } from "antd";
import React, { Component } from "react";
import { api } from "../../../config";

class VisitorForm extends Component {
  state = {
    loading: false,
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        this.setState({ loading: true });
        api
          .put("/visitors/", JSON.stringify(val))
          .then((res) => {
            if (res.data && res.data.success) {
              message.success("Visitor Added");
              this.props.history.goBack();
            } else {
              message.error(res.data.error);
            }
            this.setState({ loading: false });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout.Content>
        <h1 className="align-center">Visitor Form</h1>
        <Form labelCol={{ sm: 8 }} wrapperCol={{ sm: 8 }}>
          <FormItem label="Visitor Name">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "Visitor name is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Mobile">
            {getFieldDecorator("mobile", {
              rules: [{ required: true, message: "Mobile is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Address">
            {getFieldDecorator("address", {
              rules: [{ required: true, message: "Address is requried!" }],
            })(<Input.TextArea />)}
          </FormItem>
          {/* <FormItem label="Photo">
            {getFieldDecorator("photo")(<Input />)}
          </FormItem> */}
          {/* <FormItem label="Person Visiting">
            {getFieldDecorator("person_visiting")(<Input />)}
          </FormItem> */}
          <FormItem label="Reason Visiting">
            {getFieldDecorator("reason_visiting", {
              rules: [{ required: true, message: "Reason is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem label="Entry Gate">
            {getFieldDecorator("entry_gate", {
              rules: [{ required: true, message: "Entry Gate is required!" }],
            })(<Input />)}
          </FormItem>
          <FormItem wrapperCol={{ offset: 8 }}>
            <Button
              onClick={this.handleSubmit}
              type="primary"
              loading={this.state.loading}
            >
              Save
            </Button>
          </FormItem>
        </Form>
      </Layout.Content>
    );
  }
}

export default Form.create()(VisitorForm);
