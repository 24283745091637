import {
  Layout,
  message,
  Icon,
  Table,
  Row,
  Col,
  Button,
  Popconfirm,
} from "antd";
import React, { Component } from "react";
import { api } from "../../../../config";
import moment from "moment";
import { Currency } from "../../../../components";

class EmployeesList extends Component {
  state = {
    employees: [],
  };

  componentDidMount() {
    this._get_employees_list();
  }

  _get_employees_list = () => {
    api
      .get("/simple/payroll/employees/?type=simple_payroll")
      .then((res) => {
        if (res.data.success) {
          this.setState({ employees: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  __delete_employee = (id) => {
    api
      .delete(`/hr/employee/${id}/`)
      .then((res) => {
        if (res.data.success) {
          message.success("Employee Deleted");
          this._get_employees_list();
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { employees } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Job Title", dataIndex: "job_title" },
      {
        title: "DoB",
        dataIndex: "dob",
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      {
        title: "CTC",
        dataIndex: "ctc",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Actions",
        render: (r) => (
          <span>
            <Icon
              type="edit"
              className="action-icon"
              title="Edit Employee Details"
              onClick={() =>
                this.props.history.push({
                  pathname: `/hr/employee/${r.id}/`,
                  state: { employee: r },
                })
              }
            />
            <Icon
              type="dollar"
              className="action-icon"
              title="Salary"
              onClick={() => {
                this.props.history.push(`/payroll/employee/${r.id}/salary`);
              }}
            />
            <Popconfirm
              title="Are you sure?"
              onConfirm={this.__delete_employee.bind(this, r.id)}
            >
              <Icon
                type="delete"
                className="action-icon absent"
                title="Delete"
              />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col sm={12}>
            <h1>Employees List</h1>
          </Col>
          <Col sm={12} className="action-buttons">
            <Button
              type="primary"
              ghost
              onClick={() => this.props.history.push("/hr/departments/")}
            >
              Departments
            </Button>
            <Button
              type="primary"
              onClick={() => this.props.history.push("/payroll/salary/")}
              ghost
              style={{ marginLeft: 10 }}
            >
              Set Employee Salary
            </Button>
            <Button
              type="primary"
              icon="plus"
              style={{ marginLeft: 10 }}
              onClick={() => this.props.history.push("/hr/employee/new")}
            >
              Add Employee
            </Button>
          </Col>
        </Row>
        <Table columns={columns} dataSource={employees} rowKey="id" />
      </Layout.Content>
    );
  }
}

export default EmployeesList;
