import React from "react";
import { Switch, Route } from "react-router";

import {
  AdminAcademicsRouter,
  AdminStudentsRouter,
  AdminTeachersRouter,
  AdminFeesRouter,
  AdminMarksRouter,
  AdminAnnouncementRouter,
  AdminTimetableRouter,
  AdminInventoryRouter,
  AdminActionsRouter,
  AdminDashboardRouter,
  AdminAttendanceRouter,
  AdminMessagesRouter,
  AdminCertificatesRouter,
  AdminTransportationRouter,
  AdminFinanceRouter,
  AdminHomeworkRouter,
  AdminReportsRouter,
  AdminSimpleExpensesRouter,
  AdminHelpRouter,
  AdminAdmissionsRouter,
  AdminLibraryRouter,
  AdminSettingsRouter,
  AdminVisitorsRouter,
  AdminSimpleTransportationRouter,
  AdminHrRouter,
  AdminMeetingRouter,
  AdminVirtualClassroomRouter,
  AdminOnlineTestRouter,
  AdminExamRouter,
  AdminFilesRouter,
  AdminSimpleTestsRouter,
  AdminSimplePayrollRouter,
  AdminSimpleAccountsRouter,
  AdminChatRouter,
  AdminSimpleFinanceDashboardRouter,
  AdminHostelRouter,
  AdminBillingRouter,
  AdminUsersRouter,
  AdminNotesRouter,
} from "../pages/admin";

import {
  TeacherAttendanceRouter,
  TeacherStudentsRouter,
  TeacherMarksRouter,
  TeacherTimetableRouter,
  TeacherDashboardRouter,
  TeacherNotesRouter,
  TeacherAnnouncementRouter,
  TeacherTestsRouter,
  TeacherMessagesRouter,
  TeacherHomeworkRouter,
  TeacherQuestionPaperRouter,
  TeacherVirtualClassroomRouter,
  TeacherMeetingRouter,
  TeacherOnlineTestRouter,
  TeacherSimpleTestRouter,
  TeacherChatRouter,
  TeacherPayrollRouter,
} from "../pages/teacher";

import {
  StudentTimetableRouter,
  StudentMarksRouter,
  StudentAttendanceRouter,
  StudentDashboardRouter,
  StudentNotesRouter,
  StudentHomeworkRouter,
  StudentVirtualClassroomRouter,
  StudentExamRouter,
  StudentFeesRouter,
  StudentMeetingRouter,
  StudentOnlineTestRouter,
  StudentSimpleTestRouter,
  StudentChatRouter,
} from "../pages/student";

import Logout from "../pages/guestScreens/Logout";
import ProfileScreen from "../pages/userScreens/Profile";
import SettingsScreen from "../pages/userScreens/Settings";
import Login from "../pages/guestScreens/Login";
import ForgotPassword from "../pages/guestScreens/ForgotPassword";
import ResetPassword from "../pages/guestScreens/ResetPassword";
import { LibrarianLibraryRouter } from "../pages/librarian/library";
import ModulesList from "../pages/admin/settings/ModulesList";
import { AdmissionFormRouter } from "../pages/guestScreens/admission_form";

const StudentRouter = (props) => (
  <Switch>
    <Route exact path="/" component={StudentDashboardRouter} />
    <Route exact path="/" component={StudentDashboardRouter} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/profile" component={ProfileScreen} />
    <Route exact path="/settings" component={SettingsScreen} />
    <Route exact path="/timetable" component={StudentTimetableRouter} />
    <Route exact path="/marks" component={StudentMarksRouter} />
    <Route exact path="/attendance" component={StudentAttendanceRouter} />
    <Route path="/notes/" component={StudentNotesRouter} />
    <Route path="/homework/" component={StudentHomeworkRouter} />
    <Route
      path="/virtual-classroom/"
      component={StudentVirtualClassroomRouter}
    />
    <Route path="/exam/" component={StudentExamRouter} />
    <Route path="/fees/" component={StudentFeesRouter} />
    <Route path="/meeting/" component={StudentMeetingRouter} />
    <Route path="/online-test/" component={StudentOnlineTestRouter} />
    <Route path="/simple-test/" component={StudentSimpleTestRouter} />
    <Route path="/chat/" component={StudentChatRouter} />
  </Switch>
);

const TeacherRouter = (props) => (
  <Switch>
    <Route exact path="/" component={TeacherDashboardRouter} />
    <Route exact path="/" component={TeacherDashboardRouter} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/profile" component={ProfileScreen} />
    <Route exact path="/settings" component={SettingsScreen} />
    <Route path="/attendance/" component={TeacherAttendanceRouter} />
    <Route path="/students" component={TeacherStudentsRouter} />
    <Route path="/marks" component={TeacherMarksRouter} />
    <Route path="/timetable" component={TeacherTimetableRouter} />
    <Route path="/notes/" component={TeacherNotesRouter} />
    <Route path="/announcements/" component={TeacherAnnouncementRouter} />
    <Route path="/academics/" component={TeacherTestsRouter} />
    <Route path="/messages/" component={TeacherMessagesRouter} />
    <Route path="/homework/" component={TeacherHomeworkRouter} />
    <Route path="/question-paper/" component={TeacherQuestionPaperRouter} />
    <Route
      path="/virtual-classroom/"
      component={TeacherVirtualClassroomRouter}
    />
    <Route path="/meeting/" component={TeacherMeetingRouter} />
    <Route path="/online-test/" component={TeacherOnlineTestRouter} />
    <Route path="/simple-test/" component={TeacherSimpleTestRouter} />
    <Route path="/chat/" component={TeacherChatRouter} />
    <Route path="/payroll" component={TeacherPayrollRouter} />
  </Switch>
);

const AdminRouter = (props) => (
  <Switch>
    <Route exact path="/" component={AdminDashboardRouter} />
    <Route exact path="/" component={AdminDashboardRouter} />
    <Route exact path="/logout" component={Logout} />
    <Route exact path="/profile" component={ProfileScreen} />
    <Route exact path="/settings" component={AdminSettingsRouter} />
    <Route path="/academics/" component={AdminAcademicsRouter} />
    <Route path="/attendance/" component={AdminAttendanceRouter} />
    <Route path="/students/" component={AdminStudentsRouter} />
    <Route path="/teachers/" component={AdminTeachersRouter} />
    <Route path="/fees/" component={AdminFeesRouter} />
    <Route path="/marks" component={AdminMarksRouter} />
    <Route path="/announcements" component={AdminAnnouncementRouter} />
    <Route path="/timetable" component={AdminTimetableRouter} />
    <Route path="/inventory" component={AdminInventoryRouter} />
    <Route path="/admin-actions" component={AdminActionsRouter} />
    <Route path="/messages" component={AdminMessagesRouter} />
    <Route path="/certificate" component={AdminCertificatesRouter} />
    <Route path="/transportation/" component={AdminTransportationRouter} />
    <Route path="/homework/" component={AdminHomeworkRouter} />
    <Route path="/reports/" component={AdminReportsRouter} />
    <Route path="/expenses/" component={AdminSimpleExpensesRouter} />
    <Route path="/help/" component={AdminHelpRouter} />
    <Route path="/admissions/" component={AdminAdmissionsRouter} />
    <Route path="/library/" component={AdminLibraryRouter} />
    <Route path="/visitors/" component={AdminVisitorsRouter} />
    <Route path="/hr/" component={AdminHrRouter} />
    <Route
      path="/simple-transportation/"
      component={AdminSimpleTransportationRouter}
    />
    <Route path="/virtual-classroom/" component={AdminVirtualClassroomRouter} />
    <Route path="/meeting" component={AdminMeetingRouter} />
    <Route path="/online-test/" component={AdminOnlineTestRouter} />
    <Route path="/exam/" component={AdminExamRouter} />
    <Route path="/finance/" component={AdminFinanceRouter} />
    <Route path="/files/" component={AdminFilesRouter} />
    <Route path="/simple-test/" component={AdminSimpleTestsRouter} />
    <Route path="/payroll/" component={AdminSimplePayrollRouter} />
    <Route path="/accounts/" component={AdminSimpleAccountsRouter} />
    <Route path="/chat/" component={AdminChatRouter} />
    <Route
      path="/simple-finance/"
      component={AdminSimpleFinanceDashboardRouter}
    />
    <Route path="/hostel/" component={AdminHostelRouter} />
    <Route path="/notes" component={AdminNotesRouter} />
    {props.role === 4 && (
      <div>
        <Route path="/billing/" component={AdminBillingRouter} />
        <Route path="/users/" component={AdminUsersRouter} />
        <Route exact path="/modules/D6RM3AXF/" component={ModulesList} />
      </div>
    )}
  </Switch>
);

const LibrarianRouter = (props) => (
  <Switch>
    <Route path="/library/" component={LibrarianLibraryRouter} />
  </Switch>
);

const GuestRouter = (props) => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/forgot-password" component={ForgotPassword} />
    <Route exact path="/reset-password" component={ResetPassword} />
    <Route path="/admissions/apply/" component={AdmissionFormRouter} />
    <Route path="/" component={Login} />
  </Switch>
);

export {
  StudentRouter,
  TeacherRouter,
  AdminRouter,
  GuestRouter,
  LibrarianRouter,
};
