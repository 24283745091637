import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  message,
  Spin
} from "antd";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import { api } from "../../../../config";

class BusStopForm extends Component {
  state = {
    map: undefined,
    id: null,
    spinning: false,
    loading: false
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ id: this.props.match.params.id });
      this.get_bus_stop_details(this.props.match.params.id);
    }
    let map = L.map("mapid").setView([12.9772, 77.5834], 13);
    this.setState({ map });
    L.tileLayer("https://ambegaalu.in/tiles/{z}/{x}/{y}.png", {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18
    }).addTo(map);
    map.on("click", this.click_event);
  }

  get_bus_stop_details = id => {
    this.setState({ spinning: true });
    api
      .get("/bus/stop/" + id + "/")
      .then(res => {
        if (res.data.success) {
          let data = res.data.data;
          this.props.form.setFieldsValue(data);
          L.popup()
            .setLatLng([data.lat, data.lon])
            .setContent("Bus Stop")
            .openOn(this.state.map);
          this.state.map.setView([data.lat, data.lon], 15);
        } else {
          message.error(res.data.error);
        }
        this.setState({ spinning: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ spinning: false });
      });
  };

  click_event = e => {
    L.popup()
      .setLatLng([e.latlng.lat, e.latlng.lng])
      .setContent("Bus Stop")
      .openOn(this.state.map);
    this.props.form.setFieldsValue({ lat: e.latlng.lat, lon: e.latlng.lng });
  };

  add_new_bus_stop = data => {
    this.setState({ loading: true });
    api
      .put("/bus/stops/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("New Stop Added");
          this.props.history.push("/transportation/stops/");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  update_bus_stop = (id, data) => {
    this.setState({ loading: true });
    api
      .post("/bus/stop/" + id + "/", JSON.stringify(data))
      .then(res => {
        if (res.data.success) {
          message.success("Bus stop updated");
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        if (this.state.id) {
          this.update_bus_stop(this.state.id, val);
        } else {
          this.add_new_bus_stop(val);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const FormItem = Form.Item;
    return (
      <Layout.Content>
        <h1 className="align-center">Bus Stop Form</h1>
        <Row gutter={32}>
          <Col sm={24} lg={12}>
            <Spin spinning={this.state.spinning}>
              <Form wrapperCol={{ sm: 16 }} labelCol={{ sm: 8 }}>
                <FormItem label="Stop Name">
                  {getFieldDecorator("name", {
                    rules: [
                      { required: true, message: "Stop name is required!" }
                    ]
                  })(<Input placeholder="Stop Name" />)}
                </FormItem>
                <FormItem label="Latitude">
                  {getFieldDecorator("lat", {
                    rules: [
                      { required: true, message: "Latitude is required!" }
                    ]
                  })(
                    <InputNumber
                      placeholder="Latitude"
                      style={{ width: 200 }}
                    />
                  )}
                </FormItem>
                <FormItem label="Longitude">
                  {getFieldDecorator("lon", {
                    rules: [
                      { required: true, message: "Longitude is required!" }
                    ]
                  })(
                    <InputNumber
                      placeholder="Longitude"
                      style={{ width: 200 }}
                    />
                  )}
                </FormItem>
                <FormItem label="Nearby Areas">
                  {getFieldDecorator("nearby")(
                    <Select mode="tags" placeholder="Nearby Areas" />
                  )}
                </FormItem>
                <FormItem label="Stop Address">
                  {getFieldDecorator("extras.address")(
                    <Input.TextArea placeholder="Address" />
                  )}
                </FormItem>
                <FormItem label="Stop Landmark">
                  {getFieldDecorator("extras.landmark")(
                    <Input placeholder="Landmark" />
                  )}
                </FormItem>
                <FormItem wrapperCol={{ offset: 8 }}>
                  <Button
                    onClick={this.handleSubmit}
                    loading={this.state.loading}
                  >
                    {this.state.id ? "Update " : "Add "}Stop
                  </Button>
                </FormItem>
              </Form>
            </Spin>
          </Col>
          <Col sm={24} lg={12}>
            <div id="mapid" style={{ width: "100%", height: 600 }} />
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(BusStopForm);
