import React from "react";
import { Switch, Route } from "react-router-dom";
import AppUsers from "./AppUsers";
import SMSList from "./SMSList";

const AdminReportsRouter = (props) => (
  <Switch>
    <Route exact path="/reports/sms/" component={SMSList} />
    <Route exact path="/reports/app-users/" component={AppUsers} />
  </Switch>
);

export { AdminReportsRouter };
