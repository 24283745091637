import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Table,
  message,
  Icon,
  Button,
  Card,
  Empty,
} from "antd";
import {
  ClassSectionDropdown,
  AcademicYearDropdown,
  Currency,
  FeeNameDropdown,
} from "../../../../components";
import { api } from "../../../../config";
import { Pie } from "react-chartjs-2";
import { Link } from "react-router-dom";

const colors = [
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#f7a35c",
  "#8085e9",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
];

let state = {
  class_id: undefined,
  ac_year_id: undefined,
  fee_name: undefined,
  fee_records: [],
  card: {},
  reports: {},
  fee_name_list: [],
};

class FeeReports extends Component {
  constructor(props) {
    super(props);
    this.state = state;
  }

  componentWillUnmount() {
    state = this.state;
  }

  get_fee_report = (class_id, ac_year_id) => {
    this.get_fee_card_reports(class_id, ac_year_id);
    let url = "/reports/fees/" + class_id + "/" + ac_year_id + "/";
    api
      .get(url)
      .then((res) => {
        if (res.data && res.data.success) {
          this.setState({ fee_records: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  get_fee_card_reports = (class_id, ac_year_id) => {
    let url = `/reports/fees/${class_id}/${ac_year_id}/?type=card`;
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          let data = res.data.data;
          this.setState({ reports: data });
          if (data.today) this.rearrangeData(data);
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  get_fee_name_collection = () => {
    let { class_id, ac_year_id } = this.state;
    let url = `/fees/list/${class_id}/${ac_year_id}/`;
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ fee_name_list: res.data.data });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  pie_chart_data = () => {};

  get_student_fees_list = () => {
    let { class_id, ac_year_id, fee_name } = this.state;
    let url = `/fees/list/${class_id}/${ac_year_id}/?fee_name=${fee_name}`;
    // this.get_fee_name_collection();
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ fee_records: res.data.data });
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  rearrangeData = (data) => {
    let card = {};
    Object.keys(data).forEach((item) => {
      let piedata = [],
        labels = [],
        list = data[item].list;
      list.forEach((litem) => {
        if (litem.total_amount <= 0) return;
        piedata.push(litem.total_amount);
        labels.push(litem.fee_item_name);
      });
      card[item] = {
        table: [
          {
            name: "Fees Received",
            value: <Currency value={data[item].paid || 0} />,
          },
          {
            name: "Fees Generated",
            value: <Currency value={data[item].total || 0} />,
          },
        ],
        chart: {
          datasets: [
            { data: piedata, backgroundColor: colors.slice(0, piedata.length) },
          ],
          labels,
        },
        length: piedata.length,
      };
    });
    this.setState({ card });
  };

  handleClassChange = (class_id) => {
    this.setState({ class_id });
  };

  handleACYearChange = (ac_year_id) => {
    this.setState({ ac_year_id });
  };

  handleFeeNameChange = (fee_name) => {
    this.setState({ fee_name });
  };

  handleGo = () => {
    const { class_id, ac_year_id, fee_name } = this.state;
    if (fee_name === undefined || fee_name === null) {
      this.get_fee_report(class_id, ac_year_id);
    } else {
      this.get_student_fees_list();
    }
  };

  exportFeesRecords = () => {
    let { fee_records, class_id } = this.state;

    var csvContent = "data:text/csv;charset=utf-8,";
    let header =
      "ID, Student Name, Admission No, Paid Amount, Total Amount, Due Amount\r\n";
    csvContent += header;
    fee_records.forEach((item) => {
      let line = `${item.id}, ${item.fullname}, ${item.admission_no}, ${
        item.paid_amount
      }, ${item.total_amount}, ${item.total_amount - item.paid_amount}\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `fees_records_class_${class_id}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  _export_fee_items_reports = () => {
    const { reports, class_id } = this.state;
    if (reports && reports.total && reports.total.list) {
      let { list } = reports.total;
      console.log(list);
      var csvContent = "data:text/csv;charset=utf-8,";
      let header = "ID,Fee Item Name,Amount,Quantity,Count,Total Amount\r\n";
      csvContent += header;
      list.forEach((item) => {
        let line = `${item.id || " - "},${item.fee_item_name},${item.amount},${
          item.quantity || " - "
        },${item.count},${item.total_amount}\r\n`;
        csvContent += line;
      });
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `fee_items_report_${class_id}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      message.error("No data to export");
    }
  };

  render() {
    const columns = [
      { title: "Admn. No", dataIndex: "admission_no" },
      { title: "Name", dataIndex: "fullname" },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Amount Paid",
        dataIndex: "paid_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Due Amount",
        align: "right",
        render: (record) => (
          <span>
            <Currency value={record.total_amount - record.paid_amount} />
          </span>
        ),
        sorter: (a, b) =>
          a.total_amount - a.paid_amount - (b.total_amount - b.paid_amount),
      },
      {
        title: "Actions",
        render: (record) => (
          <span>
            <Icon
              type="dollar"
              className="action-icon"
              onClick={() => {
                this.props.history.push(
                  "/fees/records/" + (record.student_id || record.id)
                );
              }}
            />
          </span>
        ),
      },
    ];
    const { fee_records, card, reports, class_id, ac_year_id, fee_name } =
      this.state;
    const chart_style = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    };
    const reports_columns = [
      {
        title: "Fee Item Name",
        dataIndex: "fee_item_name",
        render: (v, r) => (
          <Link
            to={{
              pathname: "/fees/reports/item",
              state: { fee_report: r, class_id, ac_year_id },
            }}
          >
            {v}
          </Link>
        ),
      },
      {
        title: "Total Amount",
        dataIndex: "total_amount",
        align: "right",
        render: (v) => <Currency value={v} />,
      },
      { title: "Quantity", dataIndex: "quantity", align: "right" },
      { title: "Count", dataIndex: "count", align: "right" },
    ];
    return (
      <Layout.Content>
        <h1>Fee Reports</h1>
        <Row className="ribbon-row">
          <Col sm={16}>
            <span>
              <strong>Class : </strong>
              <ClassSectionDropdown
                onChange={this.handleClassChange}
                value={class_id}
              />
            </span>
            <span style={{ marginLeft: 8 }}>
              <strong>AC Year : </strong>
              <AcademicYearDropdown
                onChange={this.handleACYearChange}
                value={ac_year_id}
              />
            </span>
            <span style={{ marginLeft: 8 }}>
              <strong>Fee Name : </strong>
              <FeeNameDropdown
                onChange={this.handleFeeNameChange}
                value={fee_name}
                class_id={class_id}
                ac_year_id={ac_year_id}
              />
            </span>
            <span style={{ marginLeft: 12 }}>
              <Button onClick={this.handleGo}>Go</Button>
            </span>
          </Col>
          <Col sm={8} className="action-buttons">
            <Button
              icon="export"
              disabled={fee_records.length === 0}
              onClick={this.exportFeesRecords}
            >
              Export
            </Button>
          </Col>
        </Row>
        {card.today && (
          <Row gutter={16} type="flex">
            <Col lg={6} className="card-row">
              <Card title="Today" className="admin-card card-elevation">
                <CardTable data={card.today.table} />
              </Card>
            </Col>
            <Col lg={6} className="card-row">
              <Card title="This Week" className="admin-card card-elevation">
                <CardTable data={card.week.table} />
              </Card>
            </Col>
            <Col lg={6} className="card-row">
              <Card title="This Month" className="admin-card card-elevation">
                <CardTable data={card.month.table} />
              </Card>
            </Col>
            <Col lg={6} className="card-row">
              <Card title="Total" className="admin-card card-elevation">
                <CardTable data={card.total.table} />
              </Card>
            </Col>
          </Row>
        )}
        <Card title="Student Fees List">
          <Table
            columns={columns}
            dataSource={this.state.fee_records}
            rowKey="admission_no"
            pagination={{ defaultPageSize: 50 }}
          />
        </Card>
        <Row gutter={16} style={{ marginTop: 16 }} type="flex">
          <Col sm={12} className="card-row">
            <Card
              title="Fee Items Reports"
              className="admin-card card-elevation"
              extra={
                <Button
                  size="small"
                  icon="export"
                  onClick={this._export_fee_items_reports}
                >
                  CSV
                </Button>
              }
            >
              <Table
                columns={reports_columns}
                dataSource={reports.total ? reports.total.list : []}
                pagination={false}
                size="small"
              />
            </Card>
          </Col>
          <Col sm={12} className="card-row">
            <Card title="Fee Items Chart" className="admin-card card-elevation">
              <div style={chart_style}>
                {card.total ? (
                  <Pie data={card.total.chart} legend={{ display: true }} />
                ) : (
                  <Empty />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

export default FeeReports;

const CardTable = (props) => (
  <div>
    <table style={{ width: "100%" }}>
      <tbody>
        {props.data &&
          props.data.map((item, index) => {
            return (
              <tr key={index}>
                <td style={{ width: "80%" }}>{item.name}</td>
                <td style={{ width: "20%", textAlign: "right" }}>
                  {item.value}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  </div>
);
