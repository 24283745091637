import React, { Component } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Form,
  Icon,
  TimePicker,
  InputNumber,
  message
} from "antd";
import L from "leaflet";
import { BusStopsDropdown } from "../../../../components";
import { api } from "../../../../config";
import moment from "moment";
import polyline from "polyline";

// var moment = require("moment");

class BusRouteStops extends Component {
  state = {
    rows_count: 2,
    map: undefined,
    route_id: null,
    source: null,
    destination: null,
    update: false
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.setState({ route_id: this.props.match.params.id });
      this.get_bus_route_stops(this.props.match.params.id);
      this.get_route_data(this.props.match.params.id);
    }
    // this.show_map();
  }

  show_map = () => {
    let map = L.map("mapid").setView([12.897997, 77.582672], 15);
    L.tileLayer("https://ambegaalu.in/tiles/{z}/{x}/{y}.png", {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18
    }).addTo(map);
    // this.setState({ map }, this.draw_route);
  };

  draw_route = () => {
    let route_path = polyline.decode(
      "kftmAipoxMCuDsNNiH@CKMi@dBgUsA|VqJZgKOkDe@{EmC"
    );
    L.polyline(route_path, { color: "red" }).addTo(this.state.map);
  };

  get_bus_route_stops = id => {
    api
      .get("/bus/routes/stops/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          let data = res.data.data;
          if (data.length > 0) {
            this.setState({ update: true });
          }
          data.map(item => {
            item.arr_time = moment(item.arr_time);
            return item;
          });
          this.props.form.setFieldsValue({ stops: data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  get_route_data = id => {
    api
      .get("/bus/route/" + id + "/")
      .then(res => {
        if (res.data && res.data.success) {
          this.setState({
            source: res.data.data.source,
            destination: res.data.data.destination
          });
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  incCount = () => {
    let { rows_count } = this.state;
    rows_count++;
    this.setState({ rows_count });
  };

  decCount = () => {
    let { rows_count } = this.state;
    rows_count--;
    this.setState({ rows_count });
  };

  add_bus_route_stops = data => {
    api
      .put("/bus/routes/stops/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Bus Route Stops Added");
          this.props.history.push(
            "/transportation/routes/stops/" + this.state.route_id
          );
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  update_bus_route_stops = data => {
    message.error("Update currently not available");
    api
      .post("/bus/routes/stops/", JSON.stringify(data))
      .then(res => {
        if (res.data && res.data.success) {
          message.success("Bus Route Stops Added");
        } else {
          message.error(res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleSubmit = () => {
    this.props.form.validateFields((err, val) => {
      if (!err) {
        // Todo : check this.state.update and if true call api.post(), instead of put
        let data = { route_id: this.state.route_id, ...val };
        if (this.state.update) {
          this.update_bus_route_stops(data);
        } else {
          this.add_bus_route_stops(data);
        }
      }
    });
  };

  render() {
    const FormItem = Form.Item;
    const { getFieldDecorator } = this.props.form;
    const rc = this.state.rows_count;
    const { source, destination } = this.state;
    return (
      <Layout.Content>
        <Row>
          <Col lg={12}>
            <h1>Add Stops to Route</h1>
          </Col>
          <Col lg={12} style={{ textAlign: "right" }}>
            <Button onClick={this.handleSubmit}>Save</Button>
          </Col>
        </Row>
        <Row gutter={32}>
          <Col xl={12}>
            <Form>
              <div className="timetable-table">
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Bus Stop</th>
                      <th>Time</th>
                      <th>Amount</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <FormItem style={{ marginBottom: 0 }}>
                          {getFieldDecorator(`stops[0].bus_stop_id`, {
                            initialValue: source
                          })(
                            <BusStopsDropdown
                              style={{ width: 150 }}
                              disabled={true}
                            />
                          )}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem style={{ marginBottom: 0 }}>
                          {getFieldDecorator(`stops[0].arr_time`)(
                            <TimePicker format="HH:mm" />
                          )}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem style={{ marginBottom: 0 }}>
                          {getFieldDecorator(`stops[0].amount`, {
                            initialValue: 0
                          })(<InputNumber />)}
                        </FormItem>
                      </td>
                    </tr>
                    {[...Array(rc - 1).keys()].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item + 2}</td>
                          <td>
                            <FormItem style={{ marginBottom: 0 }}>
                              {getFieldDecorator(
                                `stops[${item + 1}].bus_stop_id`,
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Bus stop is required"
                                    }
                                  ],
                                  initialValue: null
                                }
                              )(<BusStopsDropdown style={{ width: 150 }} />)}
                            </FormItem>
                          </td>
                          <td>
                            <FormItem style={{ marginBottom: 0 }}>
                              {getFieldDecorator(
                                `stops[${item + 1}].arr_time`,
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "Arrival time is required"
                                    }
                                  ]
                                }
                              )(<TimePicker format="HH:mm" />)}
                            </FormItem>
                          </td>
                          <td>
                            <FormItem style={{ marginBottom: 0 }}>
                              {getFieldDecorator(`stops[${item + 1}].amount`, {
                                rules: [
                                  {
                                    required: true,
                                    message: "Amount is required"
                                  }
                                ]
                              })(<InputNumber />)}
                            </FormItem>
                          </td>
                          <td>
                            {rc !== 2 && item === rc - 2 ? (
                              <span>
                                <Icon
                                  type="plus-circle"
                                  className="action-icon"
                                  onClick={this.incCount}
                                />
                                <Icon
                                  type="minus-circle"
                                  className="action-icon"
                                  onClick={this.decCount}
                                />
                              </span>
                            ) : rc === 2 ? (
                              <Icon
                                type="plus-circle"
                                className="action-icon"
                                onClick={this.incCount}
                              />
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>{rc + 1}</td>
                      <td>
                        <FormItem style={{ marginBottom: 0 }}>
                          {getFieldDecorator(`stops[${rc}].bus_stop_id`, {
                            initialValue: destination,
                            rules: [
                              {
                                required: true,
                                message: "Bus Stop is required"
                              }
                            ]
                          })(
                            <BusStopsDropdown style={{ width: 150 }} disabled />
                          )}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem style={{ marginBottom: 0 }}>
                          {getFieldDecorator(`stops[${rc}].arr_time`, {
                            rules: [
                              {
                                required: true,
                                message: "Arrival time is required"
                              }
                            ]
                          })(<TimePicker format="HH:mm" />)}
                        </FormItem>
                      </td>
                      <td>
                        <FormItem style={{ marginBottom: 0 }}>
                          {getFieldDecorator(`stops[${rc}].amount`, {
                            rules: [
                              {
                                required: true,
                                message: "Amount is required"
                              }
                            ]
                          })(<InputNumber />)}
                        </FormItem>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Form>
          </Col>
          {/* <Col xl={12}>
            <div id="mapid" style={{ width: "100%", height: 600 }} />
          </Col> */}
        </Row>
      </Layout.Content>
    );
  }
}

export default Form.create()(BusRouteStops);
