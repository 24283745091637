import React, { Component } from "react";
import { Button, Col, Layout, message, Row, Table } from "antd";
import { api } from "../../../config";
import moment from "moment";

class VisitorsList extends Component {
  state = {
    visitors: [],
    spinning: false,
  };

  componentDidMount() {
    this.get_visitors_list();
  }

  get_visitors_list = () => {
    api
      .get("/visitors/")
      .then((res) => {
        if (res.data.success) {
          this.setState({ visitors: res.data.data });
        } else {
          message.error(res.data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const columns = [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Mobile", dataIndex: "mobile" },
      {
        title: "Entry",
        dataIndex: "entry_timestamp",
        render: (v) => (
          <span>{moment.utc(v).local().format("DD MMM, YYYY hh:mm A")}</span>
        ),
      },
      {
        title: "Exit",
        dataIndex: "exit_timestamp",
        render: (v) => (
          <span>{moment.utc(v).local().format("DD MMM, YYYY hh:mm A")}</span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row>
          <Col md={12}>
            <h1>Visitors List</h1>
          </Col>
          <Col md={12} className="action-buttons">
            <Button
              icon="plus"
              type="primary"
              className="action-button"
              onClick={() => this.props.history.push("/visitors/new/")}
            >
              New Visitor
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={this.state.visitors}
          rowKey="id"
          loading={this.state.spinning}
        />
      </Layout.Content>
    );
  }
}

export default VisitorsList;
