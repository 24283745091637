import React from "react";
import { Switch, Route } from "react-router";

import { FeeItemsList, FeeItemsForm, FeeItemReports } from "./items";
import {
  PayFees,
  FeeReceipt,
  FeePaymentRecord,
  PayFeesInstallment,
  FeeRefund,
} from "./pay";
import { FeeItemStudentsReports, FeeReports } from "./reports";
import { FeesList, CreateFees, StudentsList } from "./create";

const AdminFeesRouter = () => (
  <Switch>
    <Route exact path="/fees/items/" component={FeeItemsList} />
    <Route exact path="/fees/items/new" component={FeeItemsForm} />
    <Route exact path="/fees/items/:id" component={FeeItemsForm} />
    <Route exact path="/fees/item/reports" component={FeeItemReports} />

    <Route exact path="/fees/records/:id" component={FeePaymentRecord} />
    <Route
      exact
      path="/fees/pay/installment/:id"
      component={PayFeesInstallment}
    />
    <Route exact path="/fees/pay/:id" component={PayFees} />
    <Route exact path="/fees/receipt/:id" component={FeeReceipt} />

    <Route exact path="/fees/reports/" component={FeeReports} />
    <Route exact path="/fees/reports/item" component={FeeItemStudentsReports} />

    <Route exact path="/fees/create/" component={FeesList} />
    <Route exact path="/fees/create/new/" component={CreateFees} />
    <Route exact path="/fees/create/reports/" component={StudentsList} />
    <Route exact path="/fees/refund/:id" component={FeeRefund} />
  </Switch>
);

export { AdminFeesRouter };
