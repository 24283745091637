import {
  Button,
  Col,
  Layout,
  message,
  Row,
  Table,
  Tag,
  Icon,
  Popconfirm,
  Input,
  DatePicker,
} from "antd";
import React, { Component } from "react";
import moment from "moment";
import { api } from "../../../../config";
import { Currency, PrintTable } from "../../../../components";
import Highlighter from "react-highlight-words";

class Incomes extends Component {
  state = {
    loading: false,
    deposits: [],
    searchText: undefined,
    searchColumn: undefined,
    date_range: [moment().startOf("M"), moment()],
  };

  componentDidMount() {
    this._get_deposits_list();
  }

  _get_deposits_list = () => {
    this.setState({ loading: true });
    let { date_range } = this.state;
    let data = {
      start_date: moment.isMoment(date_range[0])
        ? date_range[0].format("YYYY-MM-DD")
        : "",
      end_date: moment.isMoment(date_range[1])
        ? date_range[1].format("YYYY-MM-DD")
        : "",
    };
    let url = `/finance/deposits/?start_date=${data.start_date}&end_date=${data.end_date}`;
    api
      .get(url)
      .then((res) => {
        if (res.data.success) {
          this.setState({ deposits: res.data.data });
        } else {
          message.error(res.data.error);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  _handle_delete = (r) => {
    api
      .delete(`/finance/deposit/${r.id}/`)
      .then((res) => {
        if (res.data.success) {
          if (r.deposit_type === 3) {
            message.success(
              "Deposit deleted, please remove this from expenses as well"
            );
          } else {
            message.success("Deposit deleted!");
          }
          this._get_deposits_list();
        } else {
          message.error(res.data.error || "Something went wrong!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  exportDepositReports = () => {
    let { deposits } = this.state;

    var csvContent = "data:text/csv;charset=utf-8,";
    let header = "ID,Name,Date,Amount\r\n";
    csvContent += header;
    deposits.forEach((item) => {
      let line = `${item.id},${item.name},${moment(item.date).format(
        "YYYY-MM-DD"
      )},${item.amount}\r\n`;
      csvContent += line;
    });
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `deposit_reports_${new Date().getTime()}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search by name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, r) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        <span>{text}</span>
      ),
  });

  render() {
    const { loading, deposits, date_range } = this.state;
    const columns = [
      { title: "ID", dataIndex: "id", print: true },
      {
        title: "Name",
        dataIndex: "name",
        print: true,
        ...this.getColumnSearchProps("name"),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        align: "right",
        print: "true",
        render: (v) => <Currency value={v} />,
      },
      {
        title: "Type",
        dataIndex: "deposit_type",
        print: true,
        render: (v, r) => {
          switch (v) {
            case 1:
              return <Tag>Fees</Tag>;
            case 2:
              return <Tag>Donations</Tag>;
            case 3:
              return <Tag>Transfer</Tag>;
            case 4:
              return <Tag>Others</Tag>;
            default:
              return <Tag>Unknown</Tag>;
          }
        },
      },
      {
        title: "Date",
        dataIndex: "date",
        print: true,
        render: (v) => (
          <span>{moment.utc(v).local().format("YYYY-MM-DD")}</span>
        ),
      },
      { title: "Mode", dataIndex: "mode", print: true },
      {
        title: "Actions",
        print: false,
        render: (r) => (
          <span>
            <Popconfirm
              title="Are you sure?"
              onConfirm={this._handle_delete.bind(this, r)}
            >
              <Icon type="delete" className="action-icon absent" />
            </Popconfirm>
          </span>
        ),
      },
    ];
    return (
      <Layout.Content>
        <Row gutter={16}>
          <Col sm={4}>
            <h1>Incomes</h1>
          </Col>
          <Col sm={12}>
            <DatePicker.RangePicker
              value={date_range}
              onChange={(v) => this.setState({ date_range: v })}
            />
            <Button style={{ marginLeft: 8 }} onClick={this._get_deposits_list}>
              Go
            </Button>
          </Col>
          <Col sm={8} className="action-buttons">
            <Button
              icon="export"
              type="primary"
              ghost
              onClick={this.exportDepositReports}
              style={{ marginRight: 8 }}
            >
              CSV
            </Button>
            <Button
              icon="file-pdf"
              type="primary"
              style={{ marginRight: 8 }}
              onClick={() => window.print()}
            >
              PDF
            </Button>
            <Button
              type="primary"
              icon="plus"
              onClick={() => this.props.history.push("/finance/income/new/")}
            >
              New Deposits
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={deposits}
          loading={loading}
          expandedRowRender={(r) => <div>{r.description}</div>}
          rowKey="id"
        />
        <PrintTable columns={columns} data={deposits} type="Income Reports" />
      </Layout.Content>
    );
  }
}

export default Incomes;
